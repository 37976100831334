import { Component, OnInit } from '@angular/core';
import { Post } from '../blog/post';
import { BlogService } from '../services/blog.service';

@Component({
  selector: 'app-front-page',
  templateUrl: './front-page.component.html',
  styleUrls: ['./front-page.component.scss']
})
export class FrontPageComponent implements OnInit {

  post?: Post;

  constructor(private bs: BlogService) {
    bs.getMostRecentPost().subscribe((data: Post[]) => this.post = {
      id: data[0].id,
      title: data[0].title,
      subtitle: data[0].subtitle,
      body: data[0].body,
      published: data[0].published,
      edited: data[0].edited,
    });
  }

  ngOnInit(): void {
  }

}
