<mat-card class="w-100 h-100 mx-auto">
    <mat-card-header>
        <mat-card-title>
            <p>
                {{ post.title }}
            </p>
        </mat-card-title>
        <mat-card-subtitle *ngIf="post.subtitle">
            <p>
                {{ post.subtitle }}
            </p>
        </mat-card-subtitle>
    </mat-card-header>
    <mat-card-actions>
        <button mat-button routerLink="/blog" [queryParams]="{id: post.id}">View</button>
    </mat-card-actions>
</mat-card>
