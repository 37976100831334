<nav class="navbar navbar-expand-lg navbar-dark bg-dark sticky-top">
    <div class="container-fluid">
        <span class="navbar-brand">Michael Cooper</span>
        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarNav">
            <ul class="navbar-nav me-auto mb-3 mb-lg-0">
                <li class="nav-item">
                    <a class="nav-link" routerLink="">
                        Home
                    </a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" routerLink="blog">
                        Blog
                    </a>
                </li>
                <!-- <li class="nav-item">
                    <a class="nav-link" routerLink="sandboxapp">
                        Sandbox
                    </a>
                </li> -->
                <li class="nav-item">
                    <a class="nav-link" href="https://github.com/Maxxxxz" target="_blank">
                        GitHub
                    </a>
                </li>
            </ul>

            <!-- Search... -->
            <div class="nav-item search text-lg-start me-0">
                <div style="display:flex;justify-content:center;align-items:center;">
                    <input [(ngModel)]="searchQuery" class="me-1">
                    <button mat-raised-button [disabled]="!searchQuery || searchQuery == ''" routerLink="/blog/search" [queryParams]="{query: searchQuery}" class="fs-5 me-3">Search Posts</button>
                </div>
            </div>
        </div>
    </div>
</nav>
