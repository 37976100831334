<div class="container-fluid mt-3">
    <!-- We are showing the search results -->
    <div class="row">
        <div class="col-lg-3 hidden-xs text-center">
        </div>
        <div class="col-lg-6 col-xs-12 h-100">
            <div *ngIf="searchResults.length > 0 else noResults">
                <p class="fs-5 pb-3 text-center">Search results for the query: {{ query }}</p>
                <div class="pb-3" *ngFor="let post of searchResults">
                    <app-blog-post-preview [post]="post"></app-blog-post-preview>
                </div>
            </div>
            <ng-template #noResults>
                <p class="fs-5 text-center">No posts found matching the query: {{ query }}</p>
            </ng-template>
        </div>
        <div class="col-lg-3 hidden-xs text-center">
        </div>
    </div>
</div>
