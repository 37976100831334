<div class="w-100">
    <div class="row text-center pt-2">
        <span class="fs-1 pt-2">
            {{ post.title }}
        </span>
        <span class="fs-5 pt-4" *ngIf="post.subtitle">
            {{ post.subtitle }}
        </span>
        <div>
            <div class="pt-3">
                <span class="fs-6">
                    Published: {{ post.published | date:'medium' }}
                </span>
            </div>
            <!-- <div>
                <span *ngIf="post.edited">
                    Edited: {{ post.edited | date:'medium' }}
                </span>
            </div> -->
        </div>
    </div>
    <div class="row pt-3">
        <markdown [data]="post.body"></markdown>
    </div>
</div>

