import { Component, Input, OnInit } from '@angular/core';
import { Post } from '../post';

@Component({
  selector: 'app-recent-blog-posts',
  templateUrl: './recent-blog-posts.component.html',
  styleUrls: ['./recent-blog-posts.component.scss']
})
export class RecentBlogPostsComponent implements OnInit {

  @Input()
  posts!: Post[];

  constructor() { }

  ngOnInit(): void {
  }

}
