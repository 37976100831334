import { Injectable, NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SandboxAppComponent } from './apps/sandbox-app/sandbox-app.component';
import { BlogPageComponent } from './blog/blog-page/blog-page.component';
import { FrontPageComponent } from './front-page/front-page.component';
import { MusingsComponent } from './musings/musings.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { SearchComponent } from './blog/search/search.component';

const routes: Routes = [
  // Index
  {
    path: '',
    title: "Michael\'s Site",
    component: FrontPageComponent,
  },
  // App 1...
  {
    path: 'sandboxapp',
    title: "Michael\'s Site",
    component: SandboxAppComponent,
  },
  // Musings
  {
    path: 'musings',
    title: "Michael\'s Thoughts",
    component: MusingsComponent,
  },
  // Blog
  {
    path: 'blog',
    title: "Blog",
    component: BlogPageComponent,
  },
  // Blog Search
  {
    path: 'blog/search',
    title: "Blog",
    component: SearchComponent,
  },
  // 404
  {
    path: '**',
    title: "Michael\'s Site - 404",
    component: NotFoundComponent,
  },
];

@Injectable({providedIn: 'root'})
export class ResolvedChildATitle  {
  resolve() {
    return Promise.resolve('child a');
  }
}

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
