<div class="container-fluid mt-3">
    <!-- We are showing the main page -->
    <div *ngIf="!post" class="blogposts w-50">
        <div class="row text-center">
            <h1>Recent</h1>
        </div>
        <app-recent-blog-posts [posts]="recentPosts"></app-recent-blog-posts>
    </div>
    <!-- We are showing the post -->
    <div *ngIf="post">
        <div class="container-fluid">
            <div class="row">
                <div class="col-lg-3 hidden-xs text-center">
                    <!-- <p>This is a free space. Maybe I will show recent post titles here?</p>
                    <p>Maybe it will include links to sections in the post?</p> -->
                </div>
                <div class="col-lg-6 col-xs-12 px-auto">
                    <app-blog-post [post]="post"></app-blog-post>
                </div>
                <div class="col-lg-3 hidden-xs text-center">
                    <!-- <p>This is a free space. What will I do here?</p>
                    <p>Perhaps I will use this for random announcements 🤔</p> -->
                </div>
            </div>
        </div>
    </div>
</div>
