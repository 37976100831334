import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BlogService } from 'src/app/services/blog.service';
import { Post } from '../post';

@Component({
  selector: 'app-blog-page',
  templateUrl: './blog-page.component.html',
  styleUrls: ['./blog-page.component.scss']
})
export class BlogPageComponent implements OnInit {

  post?: Post;
  recentPosts: Post[] = [];
  id?: number;

  constructor(private bs: BlogService, private route: ActivatedRoute) {
  }

  // Work on this logic lol too many issues, let's make this simpler
  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.id = params['id'];
      if(this.id)
      {
        this.bs.getPost(this.id).subscribe((data: Post) => this.post = {
          id: data.id,
          title: data.title,
          subtitle: data.subtitle,
          body: data.body,
          published: data.published,
          edited: data.edited,
        },() => {}, () => {});
        
        return;
      }
      this.post = undefined;
      if(this.recentPosts.length)
      {
        return;
      }
      this.bs.getRecentPosts(11).subscribe((data: Post[]) => data.map((post) => this.recentPosts.push({
        id: post.id,
        title: post.title,
        subtitle: post.subtitle,
        body: post.body,
        published: post.published,
        edited: post.edited,
      })));
      
    });
  }
}
