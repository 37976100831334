import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-musings',
  templateUrl: './musings.component.html',
  styleUrls: ['./musings.component.scss']
})
export class MusingsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
