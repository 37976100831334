<!-- Display our most recent -->
<div class="row mb-5">
    <app-blog-post-preview *ngIf="posts[0]" [post]="posts[0]"></app-blog-post-preview>
</div>

<!-- Display the next two -->
<div class="row mb-3">
    <app-blog-post-preview class="w-50" *ngIf="posts[1]" [post]="posts[1]"></app-blog-post-preview>
    <app-blog-post-preview class="w-50" *ngIf="posts[2]" [post]="posts[2]"></app-blog-post-preview>
</div>

<!-- Finally, display the rest -->
<div class="row">
    <div *ngFor="let post of posts | slice:3; index as i" class="w-25 mb-2">
        <app-blog-post-preview [post]="post"></app-blog-post-preview>
    </div>
</div>
