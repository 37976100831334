<div class="container-fluid mt-5">
    <h2 class="text-center fs-1">Hi, I'm Michael</h2>

    <p class="text-center fs-5">Welcome!</p>

    <div class="aboutme">
        <p class="text-center fs-5">I'm a Software Engineer who likes to make things.</p>
        <p class="text-center fs-5">Sometimes those things get finished. <i>Sometimes</i> they don't.</p>
        <p class="text-center fs-5">Join me on my journey to make somewhat pointless things.</p>
    </div>

    <!-- <p>Check out some of my apps below!</p> -->
    <!-- <app-list></app-list> -->

    <div class="row">
        <div class="blogposts col-lg-4 col-sm-8 col-xs-12" *ngIf="post">
            <div class="row text-center">
                <p>Check out my most recent blog post!</p>
            </div>
            <div class="row">
                <app-blog-post-preview [post]="post"></app-blog-post-preview>
            </div>
        </div>
    </div>
</div>
