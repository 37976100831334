import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Post } from '../blog/post';

@Injectable({
  providedIn: 'root'
})
export class BlogService {

  baseURL = "https://api.michael-cooper.dev/api";

  constructor(private http: HttpClient) {
    const inTest = location.host.includes('test') || location.host.includes('localhost');
    this.baseURL = inTest ? "https://api.test.michael-cooper.dev/api" : this.baseURL;
  }

  public getStatus() {
    return this.http.get(`${this.baseURL}/status`);
  }
  
  public getPost(id: number) {
    return this.http.get<Post>(`${this.baseURL}/blog/posts/${id}`);
  }

  public getMostRecentPost() {
    return this.getRecentPosts(1);
  }

  public getRecentPosts(count: number) {
    return this.http.get<Post[]>(`${this.baseURL}/blog/posts?count=${count}`);
  }

  public searchPosts(keyword: string) {
    return this.http.post<Post[]>(`${this.baseURL}/blog/search`, {
      keyword: keyword
    });
  }
  
}
