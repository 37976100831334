import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { AppListComponent } from './app-list/app-list.component';
import { AboutComponent } from './about/about.component';
import { FooterComponent } from './footer/footer.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { FrontPageComponent } from './front-page/front-page.component';
import { SandboxAppComponent } from './apps/sandbox-app/sandbox-app.component';
import { MusingsComponent } from './musings/musings.component';
import { RecentBlogPostsComponent } from './blog/recent-blog-posts/recent-blog-posts.component';
import { BlogPostComponent } from './blog/blog-post/blog-post.component';
import { HttpClientModule } from '@angular/common/http';
import { MarkdownModule } from 'ngx-markdown';
import { BlogPageComponent } from './blog/blog-page/blog-page.component';
import { FormsModule } from '@angular/forms';
import { BlogPostPreviewComponent } from './blog/blog-post/blog-post-preview/blog-post-preview.component';
import { SearchComponent } from './blog/search/search.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';

// Angular Material
import { MaterialModule } from './material.module';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    AppListComponent,
    AboutComponent,
    FooterComponent,
    NotFoundComponent,
    FrontPageComponent,
    SandboxAppComponent,
    MusingsComponent,
    RecentBlogPostsComponent,
    BlogPostComponent,
    BlogPageComponent,
    BlogPostPreviewComponent,
    SearchComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    MarkdownModule.forRoot(),
    HttpClientModule,
    FormsModule,
    BrowserAnimationsModule,
    MaterialModule,
    RouterModule,
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
