import { Component, OnInit } from '@angular/core';
import { Post } from '../post';
import { ActivatedRoute } from '@angular/router';
import { BlogService } from 'src/app/services/blog.service';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit {

  searchResults: Post[] = [];

  lastQuery = "";
  query?: string;

  constructor(private bs: BlogService, private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.query = params['query'];
      if(this.query)
      {
        this.search(this.query);
      }
    });
  }

  search(query: string | undefined) {
    if(!query || query == this.lastQuery) return;
    this.lastQuery = query;

    console.log("searching");
    // Change search endpoint to only return: id, title, subtitle, and published...
    this.bs.searchPosts(query).subscribe((data: Post[]) => {
      this.searchResults = [];
      data.map((post) => this.searchResults.push({
        id: post.id,
        title: post.title,
        subtitle: post.subtitle,
        body: post.body,
        published: post.published,
        edited: post.edited,
      }));
    });
  }

}
