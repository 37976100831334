import { NgModule } from '@angular/core';

import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatCardModule } from '@angular/material/card'
import { MatButtonModule } from '@angular/material/button'


@NgModule({
  imports: [
    MatSlideToggleModule,
    MatCardModule,
    MatButtonModule,
  ],
  exports: [
    MatSlideToggleModule,
    MatCardModule,
    MatButtonModule,
  ]
})
export class MaterialModule { }
